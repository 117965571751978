import * as Yup from 'yup';
import { useEffect, useState } from 'react';

interface PasswordValidationResult {
	validatePassword: () => void;
	errMsg: string;
}

const minPasswordLength = 14;

const passwordSchema = Yup.object().shape({
	password: Yup.string()
		.required('Password is required.')
		.required('Confirm Password is required.')
		.min(
			minPasswordLength,
			`Password must be at least ${minPasswordLength} characters long.`,
		),
	passwordConfirmation: Yup.string().oneOf(
		[Yup.ref('password')],
		'Passwords must match.',
	),
});

const usePasswordSchema = (
	password: string,
	passwordConfirmation: string,
): PasswordValidationResult => {
	const [errMsg, setErrMsg] = useState('');

	const validatePassword = async () => {
		try {
			await passwordSchema.validate({ password, passwordConfirmation });
			setErrMsg('');
		} catch (err) {
			if (err instanceof Yup.ValidationError) {
				setErrMsg(err.message);
			}
		}
	};

	useEffect(() => {
		validatePassword();
	}, [password, passwordConfirmation]);

	return { validatePassword, errMsg };
};

export default usePasswordSchema;
